import './App.css';
import { useEffect, useState} from 'react'
import onSubmit from './api/api';
import HashLoader from 'react-spinners/HashLoader';
import parseMarkdown from './parseMarkdown/parseMarkdown';
import allowTextareasToDynamicallyResize from './dynamicInput/dynamicInput';

function App() {
  const [apiKey, setApiKey] = useState("");
  const [inputText, setInputText] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);
  const [parsedResult, setParsedResult] = useState("");

  // each time a result is recieved from the API,
  // parses it from plain text to elements
  useEffect(() => {
    setParsedResult(parseMarkdown(result));
  }, [result]);

  // gets api key from cookies and local storage and sets it into api key input field
  useEffect(() => {
    const storedApiKey = localStorage.getItem("apiKey");
    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    onSubmit(apiKey, inputText, setResult).then(() => {
      setLoading(false);
    });
  }

  // overrides default css for HashLoader spinner
  const override = {
    display: "block",
    margin: "0 auto",
  }


  // update textarea height on input
  useEffect(() => {
    allowTextareasToDynamicallyResize();
  }, []);

  return (
    <div className="container mx-auto py-4 max-w-3xl">
      <head>
      {/* <script defer src="https://cdn.swygbro.com/public/widget/swyg-widget.js"></script>
      <title>GPTI</title>
      <meta
	name="description"
	content="일기를 쓰고 MBTI를 예측해보세요!"
/>
<meta
	name="keywords"
	content="일기,MBTI, Chat GPT, ..."
/>
<meta
	name="og:site_name"
	content="GPTI"
/>
<meta
	name="og:title"
	content="GPTI"
/>
<meta
	name="og:description"
	content="일기를 쓰고 MBTI를 예측해보세요!"
/>
<meta
	name="og:type"
	content="website"
/>
<meta
	name="og:url"
	content="https://www.gpti.swygbro.com"
/>
<meta
	name="og:image"
	content="C:\workspace\SWYG\chatgpt-open-ui\react-web\public\chatgpt-open-ui-logo-compressed.jpg"
/>
<meta
	name="twitter:title"
	content="GPTI"
/> */}

      </head>
      {/* title */}
      <h1 className="text-4xl font-bold mb-4 text-center">MBTI Based on Diary</h1>

      {/* form container */}
      <form onSubmit={handleSubmit} className="flex flex-col mx-5">



        {/* container for show api key checkbox and save api key button */}
        <div className="flex flex-row justify-between mb-6 mt-4">

        </div>


        {/* handles text prompt from user */}
        <label htmlFor="input-text" className="mb-2 font-bold">Diary</label>
        <textarea
          id="input-text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          className="border-2 rounded-xl border-blue-500 p-3 mb-10 h-auto max-h-none resize-none"
          rows={1}
        ></textarea>

        {/* handles button submit */}
        <div className="flex justify-center">
          <button type="submit" className="bg-blue-500 text-white py-3 px-4 rounded text-center w-60 flex justify-center hover:bg-blue-800" disabled={loading}>
            
            {/* when loading spinner is displayed, otherwise button is enabled */}
            {loading ? 
              <HashLoader 
                color="white"
                loading={loading}
                size={25}
                aria-label="Loading"
                cssOverride={override}
              /> : <div className="font-bold">Ask My MBTI</div>
            }
          </button>
        </div>
      </form>
      
      {/* result display */}
      <div>
        {result && (
          <h2 className="font-bold mb-2 mx-4 mt-6">Result</h2>
        )}
        {result && (
          <div className="border-2 rounded-xl border-blue-400 p-4 mt-2 mx-4">
            <div className="whitespace-pre-line">{parsedResult}</div>
          </div>
        )}
      </div>

    </div>
  );
}

export default App;
