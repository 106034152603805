import { Configuration, OpenAIApi } from 'openai';

export default async function onSubmit(apiKey, inputText, setResult) {

  try{

    const configuration = new Configuration({
     apiKey:"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"
    });

    const openai = new OpenAIApi(configuration);

    // let codeAddition = " specify language behind after starting ```";

    const completion = await openai.createChatCompletion({
      model: "gpt-3.5-turbo",
      messages: [{role: "user", content: inputText},{role:"system", content: "You must answer one of MBTI Types based on user's diary(input). On the First line, answer the type only. And then, explain about the reason starting from nextline. Speak in Korean please."}]
    });

    const txt = completion.data.choices[0].message.content;
    console.log(txt);
    setResult(txt.trimStart());
  } catch(error) {
    console.error(error);
    alert(error.message);
  }
}